/* Reset de estilos para remover estilos padrão do navegador */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Estilo para o corpo da página */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.modal-body {
  max-height: 60dvh;
  overflow: overlay;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.divisao {
  height: 70px;
}

.AppBody {
  padding: 0 2.5em;
  font-family: "Montserrat", sans-serif;
}

/* Classe para remover a estilização de <Link/> */
.linkSemEstilo {
  color: inherit;
  text-decoration: none;
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

/*Product.jsx*/
.productImage {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100px;
}

.productPage {
  font-family: "Montserrat", sans-serif;
}

.productButton {
  background-color: white;
  border-color: black;
  border-width: 0.07em;
}

.clicked {
  background-color: black;
  color: white;
}

#selectOption {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.quantity-input {
  border-left: none;
  border-right: none;
  border-color: black;
  width: 2em;
  height: 3em;
}

.quantity-button {
  background-color: white;
  border-color: black;
  width: 2em;
  font-size: 1.7em;
}

.quantity-button:first-child {
  border-right: none;
}

.quantity-button:nth-of-type(2) {
  border-left: none;
}

.add-to-cart-button {
  color: white;
  background-color: black;
  border: none;
  border-radius: 0.5em;
  width: 100%;
}

.measures svg {
  max-height: 30px;
  max-width: 30px;
  cursor: pointer;
}

.measures p {
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
}

.measuresTable h4 {
  cursor: pointer;
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

/* SearchResults.jsx */
.searchPage {
  font-family: "Montserrat", sans-serif;
}

/* Liga.jsx */
.ligaPage {
  font-family: "Montserrat", sans-serif;
}

.filterButton {
  border: none;
  background: none;
}

/* Header.jsx */

.navHeader {
  background: #152c40;
}

.navHeader i {
  line-height: 2;
  /* Mantém os icones alinhados */
}

.storeLogo {
  font-size: calc(0.6em + 0.4vw);
  font-family: Roboto, Arial, sans-serif;
  color: #fff;
  letter-spacing: 5px;
  font-weight: 900;
  text-decoration: none;
}

.imgLogo {
  width: 3em;
  height: 3em;
  user-select: none;
}

.navButton {
  background: #152c40;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
  padding-left: 2vw;
  padding-right: 0.5vw;
}

.searchInput {
  border-radius: 1em;
  border: none;
}

.searchInput:focus {
  outline: none; /* Remove a borda ao clicar */
}

.dropdown-header-user {
  position: fixed;
  top: 5.6em;
  right: 0.2em;
  background-color: #152c40;
  min-width: 10em;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0.5em;
  padding: 0.5em 0.5em;
  color: white;
}

/* Dropdown.jsx */

.transparent-half {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  opacity: 60%;
  cursor: pointer; /* Torna o cursor um ponteiro ao passar por cima */
}

.blue-half {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  width: 25vw;
  height: 100vh;
  transition: transform 0.4s ease; /* Adiciona uma transição suave */
  transform: translateX(-200%); /* Inicia a offCanvas fora da tela */
}

.blue-half.open {
  transform: translateX(0);
}

.blue-half.close {
  transform: translateX(-200%);
}

.menuImgLogo {
  width: 3em;
  height: 3em;
}

.menuLogo {
  font-size: calc(0.6em + 0.4vw);
  font-family: Roboto, Arial, sans-serif;
  color: #fff;
  letter-spacing: 4px;
  font-weight: 900;
  text-decoration: none;
}

.produtos {
  cursor: pointer;
}

.produtos:hover {
  background-color: #ececec;
}

/*Banner.jsx CSS*/
.banner {
  max-width: 100vw;
  height: 70vh;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
}

.banner-content {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat", sans-serif;
  text-align: center;
}
.banner-content h5 {
  color: #d3d3d3;
}

/*Ligas.jsx CSS*/

.ligasImg {
  overflow: hidden;
}

.ligasImg img {
  transition: transform 0.3s ease;
}

.ligasImg:hover img {
  transform: scale(1.1);
  cursor: pointer;
}

/*Destaques.jsx CSS*/
.destaques {
  margin-top: 2.5em;
  user-select: none;
}

.destaques img:first-of-type {
  max-height: 300px;
}

/*Camisas.jsx CSS*/

.camisa {
  border-style: solid;
  border-width: 0.01em;
  border-radius: 1.5em;
  border-color: #d3d3d3;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.camisa:hover {
  transform: scale(1.1); /* Aumenta a escala para criar o efeito de zoom */
}

.camisa:hover h6 {
  text-decoration: underline;
}

.camisaImg {
  border-radius: 1.5em 1.5em 0 0;
}

.linha {
  border: 0.13em solid #152c40;
  opacity: 255;
}

.camisa h6:first-of-type {
  font-size: calc(0.7em + 0.5vw);
  font-weight: 400;
}

.camisa p:nth-of-type(1) {
  font-size: calc(0.6em + 0.3vw);
}

/*Footer*/

.footer {
  color: #d3d3d3;
  background-color: #152c40;
}

.imgZap {
  max-width: 6em;
}

.footer h6:first-of-type {
  font-size: calc(0.7em + 0.5vw);
}

.copy {
  font-size: calc(0.7em + 0.3vw);
}
.footer a {
  color: #d3d3d3 !important; /* Define a cor do texto como transparente */
  text-decoration: none !important; /* Remove a decoração do texto (como sublinhado) */
}

.whatsapp-fly-button {
  position: fixed;
  z-index: 1000;
  cursor: pointer;
  bottom: 20px; /* Distância da parte inferior da página */
  right: 20px; /* Distância do lado direito da página */
  width: 3em;
  height: 3em;
  border-radius: 3em;
  background-color: #00e884;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); /* Sombra projetada para baixo e para a direita */
  user-select: none;
}

.whatsapp-fly-button i {
  color: white;
  background-color: transparent;
  font-size: 30px;
}

/* Cart.jsx */

.cartPage {
  font-family: "Montserrat", sans-serif;
}

.cartPage p {
  font-size: small;
}

.cartPage .quantity-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 1.5em;
  height: 1.5em;
}

.cartPage .quantity-input {
  font-size: 1em;
  width: 1em;
}

.cartDetails {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 100px;
  background-color: white;
}

.cart-item-title {
  cursor: pointer;
}

.cart-item-title:hover {
  text-decoration: underline;
}

.trash-button {
  background-color: transparent;
  border: none;
  width: 2.8em;
  font-size: 1.2em;
}

.cartBuyButton {
  display: block;
  background-color: #152c40;
  color: white;
  border-radius: 2em;
  font-size: small;
  width: 100%;
  max-width: 20em;
  transition: transform 0.3s;
}

.cartBuyButton:hover {
  transform: scale(1.1);
}

.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure it's on top of other elements */
}

/*Register.jsx*/
.resgisterPage {
  font-family: "Montserrat", sans-serif;
}

.resgisterPage label {
  display: block;
  margin-top: 1em;
}

.resgisterPage input {
  width: 100%;
  height: 3em;
  padding: 1em;
}

.registerButton {
  font-size: large;
  padding: 0.5em 2em 0.5em 2em;
  color: white;
  background-color: #152c40;
  border: none;
  border-radius: 0.5em;
}

/* Login.jsx */
.form-input {
  border: 1px solid black;
}

.input-group-text {
  background-color: white;
  border: none;
  padding: 0;
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid black;
}

.password-container input {
  width: 100%;
  margin-right: 0.1em; /* Espaço para o ícone */
  border: none;
}

.verify-email {
  background-color: #e4e4e4;
  border-radius: 1em;
}

/* Recover.jsx */
.recoverPage {
  height: 100dvh;
}

/*Responsividade:*/

/* Estilo para telas médias */
@media (min-width: 0px) and (max-width: 900px) {
  .imgLogo {
    width: 2em;
    height: 2em;
  }
  .navButton {
    font-size: 1.2rem;
  }
  .storeLogo {
    letter-spacing: 3px;
  }
  .divisao {
    height: 50px;
  }

  .dropdown-header-user {
    top: 4.3em;
  }

  /*Dropdown.jsx*/
  .blue-half {
    width: 45vw;
  }
  .menuImgLogo {
    width: 1.75em;
    height: 1.75em;
  }

  .menuLogo {
    font-size: calc(0.5em + 0.4vw);
    font-family: Roboto, Arial, sans-serif;
    letter-spacing: 2px;
    font-weight: 700;
  }
}

/* Estilo para telas grandes e extra grandes
 @media (min-width: 992px) {
    body {
       font-size: 18px;
    }
 }
 */
